import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/verification",
    name: "verification",
    component: () => import("@/views/verification.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/details.vue"),
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("@/views/pdfUrl.vue"),
  },
  // 快估引导页
  {
    path: "/quickValuation/help",
    name: "help",
    component: () => import("@/views/rapidValuation/help.vue"),
  },
  // 快估页
  {
    path: "/quickValuation/Home",
    name: "Homes",
    component: () => import("@/views/rapidValuation/Home.vue"),
  },
  // 二级页
  {
    path: "/quickValuation/secondarySubmit",
    name: "secondarySubmit",
    component: () => import("@/views/rapidValuation/secondarySubmit.vue"),
  },
  // 详情页
  {
    path: "/quickValuation/details",
    name: "details",
    component: () => import("@/views/rapidValuation/details.vue"),
  },
  // 快估引导页
  {
    path: "/quickValuation/v2/help",
    name: "help",
    component: () => import("@/views/rapidValuationNew/help.vue"),
  },
  // 快估页
  {
    path: "/valuation/home/v2",
    name: "Homes",
    component: () => import("@/views/rapidValuationNew/Home.vue"),
  },
  // 二级页
  {
    path: "/quickValuation/v2/secondarySubmit",
    name: "secondarySubmit",
    component: () => import("@/views/rapidValuationNew/secondarySubmit.vue"),
  },
  // 详情页
  {
    path: "/quickValuation/v2/details",
    name: "details",
    component: () => import("@/views/rapidValuationNew/details.vue"),
  },
  // 报告页面
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/report/reportMessage.vue"),
  },
  // 报告证书页面
  {
    path: "/certificateReport",
    name: "certificateReport",
    component: () => import("@/views/report/certificateReport.vue"),
  },
  // 报告页面基本信息页
  {
    path: "/reportInfo",
    name: "reportInfo",
    component: () => import("@/views/report/reportInfo.vue"),
  },
  // 报告页面基本信息页
  {
    path: "/reportMessage",
    name: "reportMessage",
    component: () => import("@/views/report/reportMsg.vue"),
  },
    // 报告页面基本信息页
    {
      path: "/reportRmsg",
      name: "reportMessage",
      component: () => import("@/views/report/rmsg.vue"),
    },
  // 减值项页面
  {
    path: "/impairment",
    name: "impairment",
    component: () => import("@/views/report/impairment.vue"),
  },
  // 轮播图页面
  {
    path: "/swiper",
    name: "swiper",
    component: () => import("@/views/report/swiper.vue"),
  },
  // 全部图片页面
  {
    path: "/imgList",
    name: "imgList",
    component: () => import("@/views/report/imgList.vue"),
  },
  // 二维码页面
  {
    path: "/eva",
    name: "eva",
    component: () => import("@/views/eva/eva.vue"),
  },
  // 二维码页面
  {
    path: "/defaultEva",
    name: "defaultEva",
    component: () => import("@/views/eva/defaultEva.vue"),
  },
  // 新版报告页面
  {
    path: "/newReport",
    name: "newReport",
    component: () => import("@/views/newReport/reportHome.vue"),
  },
  // 商城报告
  {
    path: "/mallReport",
    name: "mallReport",
    component: () => import("@/views/newReport/mallReport.vue"),
  },
  // 视频
  {
    path: "/play",
    name: "newReport",
    component: () => import("@/views/newReport/play.vue"),
  },
  // 新版报告车辆参数页面
  {
    path: "/carMessage",
    name: "newReport",
    component: () => import("@/views/newReport/carMessage.vue"),
  },
  // 新版报告减值项目
  {
    path: "/newReportMsg",
    name: "newReportMsg",
    component: () => import("@/views/newReport/newReportMsg.vue"),
  },
  // 整备报告
  {
    path: "/restore",
    name: "restore",
    component: () => import("@/views/restore/index.vue"),
  },
  {
    path: "/restoreCheck",
    name: "restoreCheck",
    component: () => import("@/views/restore/check.vue"),
  },
  {
    path: "/restoreMsg",
    name: "restoreMsg",
    component: () => import("@/views/restore/carmsg.vue"),
  },
  // 保险页面
  {
    path: "/insurance/home/v1",
    name: "Homes",
    component: () => import("@/views/insurance/home.vue"),
    meta: {
      keepAlive: true, // 不需要缓存
    },
  },
  // 保险二级页面
  {
    path: "/insurance/info/v1",
    name: "Homes",
    component: () => import("@/views/insurance/info.vue"),
  },
  // 保险缺陷页面
  {
    path: "/insurance/err/v1",
    name: "Homes",
    component: () => import("@/views/insurance/err.vue"),
  },
  // 生成报告页面
  {
    path: "/insurance/details/v1",
    name: "Homes",
    component: () => import("@/views/insurance/details.vue"),
  },
  // 输出检测报告
  {
    path: "/insurance/report/v1",
    name: "Homes",
    component: () => import("@/views/insurance/testReport.vue"),
  },
  {
    path: "/insurance/traffic/v1",
    name: "Homes",
    component: () => import("@/views/insurance/traffic.vue"),
  },
  {
    path: "/managerInfo",
    name: "Homes",
    component: () => import("@/views/manager/home.vue"),
  },
  {
    path: "/success",
    name: "Homes",
    component: () => import("@/views/manager/success.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
